require("./style.css");

import ScrollMagic from "scrollmagic";
import $ from "jquery";

const controller = new ScrollMagic.Controller();

$(function () {
  const currentWidth = $(window).width();

  if (currentWidth < 991) {
    // Remove scroll magic
    const scrollerContent = document.getElementById("scroll-content");
    scrollerContent.removeAttribute("id");
  } else {
    const $block_list = $(".block-list"),
      $block_item = $block_list.find(".block-list__item"),
      block_list_width = $block_list.outerWidth(),
      block_item_width = $block_item.outerWidth(),
      total_width = block_item_width * $block_item.length,
      travel_distance = total_width - block_list_width + 20;

    const scene = new ScrollMagic.Scene({
      triggerElement: "#second",
      duration: "200%",
      triggerHook: 0,
    })
      .setPin(".block-list")
      //.setTween(tween)
      .addTo(controller);

    scene.on("progress", function (e) {
      const progress = e.progress,
        move = -travel_distance * progress + "px";
      $block_list.css({
        transform: "translateX(" + move + ")",
      });
    });
  }

  var carousel = document.querySelector(".horizontal-carousel");
  var flkty = new Flickity(carousel, {
    cellAlign: "left",
    contain: true,
    wrapAround: true,
    pageDots: false,
    prevNextButtons: true,
    resize: true,
    imagesLoaded: true,
    groupCells: true,
    groupCells: 1,
    autoPlay: 2000,
    pauseAutoPlayOnHover: false,
    draggable: false,
  });
});

document.addEventListener("DOMContentLoaded", () => {
  const webVideo = require("../../img/main-video.mp4");
  const videoElement = document.getElementById("index-video");
  videoElement.setAttribute("src", webVideo);
  videoElement.setAttribute("type", "video/mp4");
});
